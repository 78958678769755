@import '../../styles/propertySets.css';

.root {
}

.marketplaceStroke {
  stroke: var(--marketplaceColor);
}

.successFill {
  fill: var(--successColor);
}

.checkStroke {
  stroke: var(--matterColorLight);
}
