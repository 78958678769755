@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
}

.toggleSection {
  text-align: center;
  margin-top: 60px;
  padding-bottom: 40px;
}

.toggleHeaderBlue {
  color: var(--marketplaceColorDark);
  font-size: 30px;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.toggleHeaderPink {
  color: var(--marketplaceColor);
  font-size: 30px;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.tglButton {
  position: relative;
  top: 50%;
  width: 300px;
  height: 55px;
  margin: -25px auto 0 auto;
  /* overflow: hidden; */
  overflow: visible;
  @media (--viewportSmall) {
    width: 325px;
  }
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

.tglButton.r, .tglButton.r .layer{
    border-radius: 15px;
}

.tglButton .knobs:before {
    content: "I need space";
    position: absolute;
    top: 8px;
    left: 12px;
    width: 130px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: var(--fontWeightSemiBold);
    text-align: center;
    padding: 0 4px;
    background-color: var(--marketplaceColorDark);
    border-radius: 3px;
    @media (--viewportSmall) {
      width: 150px;
    }
}

.tglButton .layer, .tglButton .knobs, .tglButton .knobs:before {
    transform: rotateZ(0);
    transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.tglButton .checkbox:checked + .knobs{
    transform: rotateZ(-180deg);
}

.tglButton .checkbox:checked + .knobs:before{
    content: 'I have space';
    background-color: var(--marketplaceColor);
    transform: rotateZ(180deg);
}

.tglButton .checkbox:checked ~ .layer{
    background-color: #ffedf6;
    transform: rotateZ(180deg);
}

.knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.rightSideGreyToggle {
  position: absolute;
  top: 2px;
  right: 40px;
  z-index: 3;

  @media (--viewportMedium) {
    top: -5px;
    right: 40px;
  }
}

.rightSideGreyToggle:hover {
  cursor: pointer;
}

.rightSideGreyToggle p{
  font-size: 16px;
}

.leftSideGreyToggle {
  position: absolute;
  top: 2px;
  left: 35px;
  z-index: 3;

  @media (--viewportMedium) {
    top: -5px;
    left: 35px;
  }
}

.leftSideGreyToggle:hover {
  cursor: pointer;
}

.leftSideGreyToggle p{
  font-size: 16px;
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  @media (--viewportMedium) {
    width: 250px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.svgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 75px;
  height: 85px;
}

.blue g {
  fill: var(--marketplaceColorDark);
}

.pink g {
  fill: var(--marketplaceColor);
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}
