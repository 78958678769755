@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    /* box-shadow: var(--boxShadowFilterButton); */
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.fieldGroupPlainCategory {
  padding: 0;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.listWithImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* max-width: 221px; */
  margin: 20px auto 0;

  @media (--viewportSmall) {
    max-width: 295px;
    margin: 0 auto;
  }

  @media (--viewportMedium) {
    justify-content: flex-start;
    max-width: 100%;
    margin: 0;
  }

  & li {
    width: calc(50% - 15px);
    padding: 0;
    margin-bottom: 17px;

    @media (--viewportSmall) {
      width: 122px;
    }

    @media (--viewportMedium) {
      margin-right: 36px;
      margin-bottom: 56px;
    }

    &:nth-child(4) {
      @media (--viewportMedium) {
        margin-right: 0;
      }
    }
  }

  & li label {
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  & input:checked + label img {
    border: 3px solid var(--customDarkBlue);

    @media (--viewportMedium) {
      border: none;
    }
  }

  & input:checked + label span {
    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
    }
  }

  & li label span {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    line-height: 21px;
  }

  & [class*='checkboxWrapper'] {
    display: none;
  }

  & img {
    width: 100%;
    height: 96px;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: 10px;

    @media (--viewportSmall) {
      width: 120px;
      height: 120px;
    }
  }
}
