@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 24px;
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
.submitLandingSearchButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
    transition: width var(--transitionStyleButton);
  }
}
.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
    transition: width var(--transitionStyleButton);
  }
}

.subCategoryItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (--viewportMedium) {
    min-height: 455px;
  }
  & .submitLandingSearchButton {
    position: absolute;
    right: 40px;
    top: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--customDarkBlue);
    color: var(--customDarkBlue);
    width: 120px;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: var(--fontWeightBold);
    margin-left: auto;
  }
  & .cancelButton {
    position: absolute;
    right: 15px;
    top: 25px;
    margin: 0;
    height: 12px;
    display: flex;
  }

  & .clearButton {
    display: none;
  }

  & .contentWrapper {
    margin-bottom: 0;
  }

  & .contentWrapper,
  & .buttonsWrapper {
    padding: 0 40px;
  }

  & .buttonsWrapper {
    margin: -48px 0 0;
    padding-bottom: 24px;
  }

  & .submitButton {
    border: 1px solid var(--customDarkBlue);
    color: var(--customDarkBlue);
    width: 120px;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: var(--fontWeightBold);
    margin-left: auto;
  }

  & .contentWrapper {
    padding: 0;

    @media (--viewportMedium) {
      padding: 0 40px;
    }
  }
}
.LandingWrapperMain {
  padding: 0;
}
.LandingWrapper {
  padding: 10px;
  @media (--viewportSmall) {
    padding: 0;
  }
}
.subcategoryHeading {
  padding: 10px 0 16px;
  font-size: 16px;
  line-height: 1;
  width: 100%;
  color: var(--matterColorDarkCustom);
  font-family: var(--fontFamilySofiapro);
  font-weight: var(--fontWeightRegular);
  letter-spacing: 0;
  /* border-top: 1px solid var(--matterColorNegative); */
  display: flex;
  @media (--viewportSmall) {
    border-bottom-width: 0px;
  }
  @media (--viewportMedium) {
    padding: 24px 39px;
    border-bottom: 1px solid var(--matterColorNegative);
    max-width: calc(100% - 22px);
    font-size: 18px;
    line-height: 11px;
    color: var(--matterColor);
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
  }
}
.subcategoryHeadingMain {
  padding: 10px 0 16px;
  font-size: 16px;
  line-height: 1;
  width: 100%;
  color: var(--matterColorDarkCustom);
  font-family: var(--fontFamilySofiapro);
  font-weight: var(--fontWeightRegular);
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  @media (--viewportSmall) {
    border-bottom-width: 0px;
    flex-direction: row;
  }
  @media (--viewportMedium) {
    padding: 24px 39px;
    max-width: calc(100% - 22px);
    font-size: 18px;
    line-height: 11px;
    color: var(--matterColor);
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
  }
}

.subcategoryHeadingActive {
  margin-bottom: 10px;

  & [class*='FilterForm_activeCategory'] {
    &:after {
      transform: rotate(180deg);
    }
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.subcategoryHeadingDesktop {
  display: none;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.subcategoryHeadingMobile {
  display: inline-block;

  @media (--viewportMedium) {
    display: none;
  }
}

.subcategorySubHeadingLanding {
  display: none;
  align-items: center;
  justify-content: flex-start !important;
  padding: 24px 40px;
  font-size: 18px;
  line-height: 11px;

  @media (--viewportMedium) {
    display: flex;
    border-top: none;
  }
}
.subcategorySubHeading {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px;
  font-size: 18px;
  line-height: 11px;

  @media (--viewportMedium) {
    display: flex;
    border-top: none;
  }
}

.subcategoryClearButton {
  border: none;
  background: none;
  box-shadow: none;
  color: var(--matterColorAnti);
  font-size: 14px;
  line-height: 21px;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.activeCategory {
  color: var(--customDarkBlue);
  font-weight: 600;
  cursor: pointer;
  @media (--viewportSmall) {
    margin-left: 2px;
  }
  &:after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 8px;
    background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='%23ffabd7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat center;
    background-size: cover;
    margin-left: 8px;

    @media (--viewportMedium) {
      background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='%234BB8C5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat center;
    }
  }
}
