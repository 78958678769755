@import '../../styles/propertySets.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.rootCategory {
  padding: 0;
  border-bottom: none;
  position: static;

  &>.filterLabel {
    margin: 0;
    padding: 0;
  }
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;
  font-size: 16px;
  line-height: 1;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterAmenitiesSelected + div, 
.filterLabelSelected {
  color: var(--marketplaceColor);
  /* padding: 0;
  margin: 0; */
}

.filterAmenitiesSelected + div > div img + span {
  font-weight: var(--fontWeightSemiBold);
}

.filterAmenitiesNotSelected + div, 
.filterLabelNotSelected {
  opacity: 0.5;
  cursor: not-allowed;

  & .labelButtonWithImg {
    pointer-events: none;
  }
}

.filterAmenitiesSelected,
.filterLabelOpened {

  & .labelButtonActive:after {
    transform: rotate(180deg);
  }
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonWithImg {
  width: 100%;

  & img {
    width: 100%;
    height: 96px;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (--viewportSmall) {
      height: 218px;
    }

    @media (--viewportMedium) {
      border-radius: 0;
    }
  }

  & span {
    display: block;
    text-align: center;
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 21px;
  }
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.clearCategoryButtonMobile {
  position: absolute;
  right: 0;
  top: 4px;
}

.plain {
  width: 100%;
  display: none;

  & :global(.DayPicker_transitionContainer__horizontal) {
    min-height: 311px;
  }
}

.isOpen {
  display: block;
}

.categorypPlain {
  position: relative;
  display: none;
}



.categoryItemAmenities {
  padding: 10px 0 0px;
  margin-bottom: 20px;
  letter-spacing: 0;
  
  @media (--viewportMedium) {
    padding: 24px 0 17px;
    letter-spacing: -0.2px;
    margin-bottom: 0;
  }

  & .clearButton {
    margin: 0;
  }

  & .filterLabel {
    font-size: 16px;
    line-height: 1;
    padding: 0;
    font-weight: var(--fontWeightRegular);
    color: var(--matterColorDarkCustom);
    margin-bottom: 16px;
    
    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      padding: 4px 0 2px;
    }
  }

  & .filterLabelSelected {

    @media (--viewportMedium) {
      color: var(--marketplaceColor);
    }
  }

  & fieldset {
    padding-left: 0;
  }

  & .labelButton:after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 8px;
    background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='%23ffabd7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
    background-size: cover;
    margin-left: 8px;
    
    @media (--viewportMedium) {
      display: none;
    }
  }

  & ul li label span {
    font-size: 14px;
    line-height: 1.5;
    color: var(--matterColorDarkCustom);
  }
}

.generalAmenitiesItem {

  & .clearButton {
    margin: 0;
  }

  & fieldset {
    padding-left: 0;
  }

  & ul li label span {
    font-size: 14px;
    line-height: 1.5;
    color: var(--matterColorDarkCustom);
  }
}
