@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }
}

.descriptionDiv {
  flex: 3;
  @media (--viewportMedium) {
    padding-right: 50px;
  }

}

.descriptionDiv p {
  font-size: 14px;
  line-height: 18px;
  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 24px;
  }
}

.descriptionDiv p:first-child {
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.descriptionDiv p:last-child {
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: var(--fontWeightSemiBold);
}

.imageContainer {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  
  @media (--viewportSmall) {
    padding: 50px;
  }

  @media (--viewportMedium) {
    padding: 0px;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
}

.mainImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
