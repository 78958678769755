@import '../../styles/propertySets.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  margin: 0 16px;
  z-index: 1000;

  width: calc(100% - 32px);

  /* Dimensions */
  /*width: 89vw;*/
  padding: 0;
  top: 76px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  /* Colors */
  background-color: var(--matterColor);
  color: var(--matterColorLight);
  
  /* Cover Topbar on desktop */
  @media (--viewportSmall) {
    bottom: 0;
    top: auto;
    width: 100vw;
    margin: 0;
    justify-content: center;
  }
  @media (--viewportMedium) {
    padding: 8px 0;
  }
}

.message {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemibold);
  margin: 16px 8px 16px 16px;
  width: 100%;
  max-width: 148px;
  
  @media screen and (min-width: 375px) {
    max-width: 250px;
  }

  @media (--viewportSmall) {
    line-height: 1;
    max-width: 500px;
  }
}

.cookieLink {
  text-decoration: underline;
  color: var(--matterColorLight);
  /*border-bottom: 1px solid var(--matterColorNegative);*/

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 2px 8px 2px 8px;
  margin: 0 16px 0 0;
  line-height: 21px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
}
