@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}
.rootLanding {
  position: absolute;
}
.longInfo {
  flex-wrap: wrap;
}

.filters {
  position: relative;
  /* height: 0; */

  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-bottom: 24px; */

  & > button {
    width: 100%;
    max-width: 125px;
    max-height: 35px;
    padding: 0;

    margin-right: 8px;
  }

  & > :last-child {
    max-height: 40px;
  }
}

.searchOptions > div {
  margin-right: 8px;
  margin-bottom: 16px;
}

.searchResultSummary,
.catTxtDiv {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound,
.catTxtSpan {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  /* margin-right: 8px; */
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: var(--matterColorNegative);
    color: var(--matterColor);
    /* box-shadow: var(--boxShadowButton); */
    box-shadow: none;
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  margin-right: 8px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  /* &:focus, */
  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    background: var(--marketplaceColorDark);
  }
}

.categoryBtn {
}

.categoryItemsWrapper {
  position: absolute;
  left: -44px;
  top: 42px;
  z-index: 2;
  width: 680px;
}
.categoryItemsWrapperLanding {
  position: absolute;
  /* left: -135px; */
  top: 55px;
  z-index: 2;
  width: 100%;
  min-width: 340px;

  @media (--viewportSmall) {
    left: 0;
    width: 680px;
  }
}
.categoryItemsWrapperLandingMobile {
  position: absolute;
  left: -135px;
  top: 55px;
  z-index: 2;
  width: 680px;
}

.categoryItems {
  position: relative;
  padding: 10px;
  /* box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.25); */
  box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 100%;
  background: var(--matterColorLight);

  @media (--viewportSmall) {
    padding: 17px 15px 24px 39px;
    border-radius: 10px;
    max-width: 680px;
  }
}
.categoryItemsMobile {
  position: relative;
  padding: 10px;
  /* box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.25); */
  box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 55%;
  background: var(--matterColorLight);
}

.categoryItemsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @apply --marketplaceSearchFilterSublabelFontStyles;
  margin: 0 0 17px;
  line-height: 27px;

  & svg {
    cursor: pointer;
  }
}

.categoryItemsHolder {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  /* max-width: calc(100% - 25px); */
}

.categoryItem {
  width: 120px;
  margin-right: 40px;

  &:nth-child(4),
  &:nth-child(8) {
    margin-right: 0;
  }

  &:nth-child(-n + 4) {
    margin-bottom: 50px;
  }

  &:last-child button span {
    display: inline-block;
    max-width: 110px;
  }
}

.categoryItemSelected {
}

.active {
  border: 1px solid var(--marketplaceColor);
  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:focus {
    border: 1px solid var(--marketplaceColor);
    background: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    background: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }
}

.submitLandingSearchButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitLandingSearchButton {
  position: absolute;
  right: 40px;
  top: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--customDarkBlue);
  color: var(--customDarkBlue);
  width: 120px;
  height: 40px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 18px;
  font-weight: var(--fontWeightBold);
  margin-left: auto;
}
