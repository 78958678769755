@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput,
.quantity {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.inputNumber input[type="number"] {
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;


  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 5px;
  }
}

@-moz-document url-prefix() {
  .inputNumber {
    & input[type="number"] {
        padding-top: 4px !important;
        padding-bottom: 3px;
      @media (--viewportMedium) {
        padding-top: 0px !important;
        padding-bottom: 0;
      }
      /*@media (--viewportMLarge) {*/
      /*  padding-top: 0px !important;*/
      /*  padding-bottom: 0;*/
      /*}*/
     }
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.discountContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 30px;
  align-items: end;
}

.discountDescription {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 32px;
}

.blockMiningBooking {
  margin-bottom: 40px;
  margin-top: 10px;
  display: flex;
}
.labelMinBook {
  margin-top: 10px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  font-family: var(--fontFamilySofiapro);
}
.minBookInput {
  width: 100px;
  max-width: 100px;
  margin-right: 20px;
  /*@media (--viewportMedium) {*/
  /*  width: 20%;*/
  /*  max-width: 20%;*/
  /*}*/
}
