@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    /* box-shadow: var(--boxShadowFilterButton); */
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.categoryClickHandler {
  width: 150px;
  margin-right: 10px;

  @media (--viewportSmall) {
    width: auto;

    &:nth-child(4),
    &:nth-child(8) {
      margin-right: 0;
    }
    &:nth-child(-n + 4) {
      margin-bottom: 50px;
    }
    &:last-child button span {
      display: inline-block;
      max-width: 110px;
    }
  }
  & .root {
    display: block;
    width: 100%;
    position: static;
  }

  & .label {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.categoryFilterItem {
  & .labelSelected {
    background: none;
    border: none;
    padding: 0;
    color: var(--matterColor);
    box-shadow: none;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover,
    &:focus {
      background: none;
      border: none;
      padding: 0;
      color: var(--matterColor);
      box-shadow: none;
    }
  }
}

.categoryImg {
  width: 120px;
  height: 120px;
  margin: 0 auto 9px;
  object-fit: cover;
}

.subCategories {
  left: 0 !important;
  top: 0 !important;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  box-shadow: none;
  border-radius: 10px;
  box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.1);
}

.categoryItem {
  width: 120px;
  margin-right: 40px;

  &:nth-child(4),
  &:nth-child(8) {
    margin-right: 0;
  }

  &:nth-child(-n + 4) {
    margin-bottom: 50px;
  }

  &:last-child button span {
    display: inline-block;
    max-width: 110px;
  }
}

.categoryClickHandler {
}

.categorySelected {
}

.categoryNotSelected {
  opacity: 0.5;
  cursor: not-allowed;

  & .root {
    pointer-events: none;
  }
}
