@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './propertySets.css';

:root {
  /* pink */
  --marketplaceColor: #ffabd7;
  /* yellow */
  /* --marketplaceColorLight: #fff5a6; */
  /* slghtly lighter pink */
  /* --marketplaceColorLight: #ffc9e6; */
  --marketplaceColorLight: #ffabd7;
  /* green */
  --marketplaceColorGreen: #8eff8c;
  /* blue */
  --marketplaceColorDark: #5cbfcc;

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23c0392b;

  --successColor: #6bde98;
  --successColorDark: #59ba7f;
  --successColorLight: #96e7b6;
  /* --successColor: #8eff8c;
  --successColorDark: #6ac269;
  --successColorLight: #aaf7a8; */
  --failColor: #ef7171;
  --failColorLight: #f39a9a;
  --attentionColor: #edc56e;
  --attentionColorLight: #f2d698;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #1b1d1f;
  --matterColor: #4a4a4a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  --customBlue: #aeecf4;
  --customDarkBlue: #4bb8c5;
  --matterColorDarkCustom: #111111;

  /* ================ Font ================ */
  --fontFamily: 'poppins', Helvetica, Arial, sans-serif;
  --fontFamilySofiapro: 'sofiapro', Helvetica, Arial, sans-serif;

  --fontWeightLow: 300;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing units ================ */

  /**
   * Note: changing --contentMaxWidth does not affect layouts with LayoutSideNavigation or search pages.
   */
  --contentMaxWidth: 1208px;
  --contentMaxWidthPages: 1120px; /* calc(Global width (1056px) + (Horizontal padding (32px) * 2) */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 2px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar heights ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 72px;

  --TopbarMobileMenu_topMargin: 56px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  --locationAutocompleteBottomPadding: 72px;

  --LocationAutocompleteInput_inputHeight: 50px;
  --LocationAutocompleteInput_sidePaddingDesktop: 36px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;

  /* ================ PageBuilder ================ */
  /* --carouselWidth will be updated dynamically through JS */
  --carouselWidth: 100vw;
}

/* ================ Global element styles ================ */

body {
  font-family: var(--fontFamily);
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}
h2 {
  @apply --marketplaceH2FontStyles;
}
h3 {
  @apply --marketplaceH3FontStyles;
}
h4 {
  @apply --marketplaceH4FontStyles;
}
h5 {
  @apply --marketplaceH5FontStyles;
}
h6 {
  @apply --marketplaceH6FontStyles;
}

input,
textarea,
select,
li {
  @apply --marketplaceDefaultFontStyles;
}

p,
pre {
  @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
}

input {
  @apply --marketplaceInputStyles;
}

textarea {
  @apply --marketplaceInputStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 0 0 10px 0;

  &::placeholder {
    padding: 0 0 10px 0;
  }

  @media (--viewportMedium) {
    padding: 4px 0 10px 0;
  }
}

.fontsLoaded {
  & body {
    font-family: var(--fontFamily);
  }
  & button {
    font-family: var(--fontFamily);
  }
}

.swal2-actions > button {
  background-color: #ffabd7 !important;
}

.swal2-actions > button:focus {
  box-shadow: 0 0 0 3px #ffabd674 !important;
}
