@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.subTitle {
  margin: 12px 0;
  
  @media (--viewportMedium) {
    margin: 16px 0;
  }
}

.priceCurrencyInvalid {
  color: var(--failColor);
}
